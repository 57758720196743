<template>
  <Page class="page-users page-show-user">
    <template #title>Dettagli utente</template>
    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'users.edit', params: { id: user.id } }">Modifica</router-link>
      <button v-if="isActive" type="button" @click.prevent="banUser" class="btn btn-outline-danger ml-2">Cancella</button>
    </template>

    <template #default>
      <div class="table-responsive">
        <table class="table table-condensed">
          <tbody>
          <tr>
            <th>Nome</th>
            <td>{{ user.name }} {{ user.surname }}</td>
          </tr>
          <tr>
            <th>Stato utente</th>
            <td>
              <template v-if="isActive">
                <p>Attivo</p>
                <button type="button" class="btn btn-outline-danger" @click.prevent="banUser">Cancella</button>
              </template>
              <template v-else-if="isDeleted">Cancellato</template>
              <template v-else-if="isPending">
                <p>Da attivare</p>
                <button type="button" class="btn btn-outline-primary" @click.prevent="activate">Attiva manualmente</button>
                <button v-if="!mailSent" type="button" class="btn btn-outline-primary ml-3" @click.prevent="resendActivationEmail">Reinvia email di attivazione</button>
              </template>
            </td>
          </tr>
          <tr>
            <th>Shop</th>
            <td>
              <router-link v-if="user.shop" :to="{ name: 'shops.show', params: { id: user.shop.id } }">{{ user.shop.name }}</router-link>
              <span v-else>-</span>
            </td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <th>Data di registrazione</th>
            <td>{{ user.registered_at }}</td>
          </tr>
          <tr>
            <th>Data di attivazione</th>
            <td>{{ user.activated_at }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Page>
</template>

<script>

import rolesMixin from '@/mixins/roles.js';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal.js';
import Page from '@/views/components/Private/Page.vue';

export default {
  mixins: [rolesMixin, confirmWithModalMixin],
  components: {
    Page,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({ mailSent: false }),
  computed: {
    isDeleted () {
      return this.user.status === 'deleted';
    },
    isActive () {
      return this.user.status === 'active';
    },
    isPending () {
      return this.user.status === 'pending';
    },
  },
  methods: {
    activate () {
      if (!this.user.id || !this.isPending) {
        return;
      }

      this.confirm('Attivare manualmente l\'utente?', 'Ok', 'Annulla')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api
            .forceUserActivation(this.user.id)
            .then(res => {
              this.$emit('update:user', res.data);
            })
            .catch(this.$log.error)
          ;
        })
      ;
    },
    resendActivationEmail () {
      if (!this.user.id || !this.isPending || this.mailSent) {
        return;
      }

      this.confirm('Inviare nuovamente la mail di attivazione?', 'Ok', 'Annulla')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api
            .resendUserActivationEmail(this.user.id)
            .then(() => {
              this.mailSent = true;
            })
            .catch(this.$log.error)
          ;
        })
      ;
    },
    banUser () {
      this.confirm('Rimuovere l\'utente?', 'Ok', 'Annulla')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api.banUser(this.user.id)
            .then(res => {
              this.$emit('update:user', res.data);
            })
            .catch(this.$log.error)
          ;
        })
      ;
    },
  },
};

</script>

<style lang="scss">

.page-show-user .table tr th {
  width: 100px;

  @include media-breakpoint-up("sm") {
    width: 40%;
  }

  @include media-breakpoint-up("md") {
    width: 200px;
  }
}

</style>
